export function wasabiDataParser(data) {
	var parsedData = {};
	data.forEach((valueArr, index) => {
		if (valueArr.length > 0) {
			dropIt(parsedData, valueArr);
		}
	});
	return parsedData;
}

const dropIt = (object, valueArr) => {
	if (valueArr.length === 0) {
		return object;
	}

	if (object.hasOwnProperty(valueArr[0])) {
		return dropIt(object[valueArr[0]], valueArr.slice(1));
	} else {
		object[valueArr[0]] = {};
		return dropIt(object[valueArr[0]], valueArr.slice(1));
	}
};
