import { wasabiDataParser } from './wasabiDataParser';
import async from 'async';

const AWS = require('aws-sdk');
export default async function wasabiActions(dataObj) {
	var accessKeyId = 'JSO45I98KMWJCH4ESSXO';
	var secretAccessKey = 'D9dK9WlVJNmp4yKJ2V3v5UbSbaAYSaIXIaECCflF';

	var wasabiEndpoint = new AWS.Endpoint('s3.us-west-1.wasabisys.com');

	var s3 = await new AWS.S3({
		endpoint: wasabiEndpoint,
		accessKeyId: accessKeyId,
		secretAccessKey: secretAccessKey
	});

	const prefix = dataObj.prefix ? dataObj.prefix + '/' : '';

	return new Promise((resolve, reject) => {
		if (dataObj.action === 'getFileInfo') {
			var headParams = { Bucket: 'mariatha' };

			headParams['Key'] = prefix + dataObj.fileName;
			console.log('headParams', headParams);
			s3.headObject(headParams).promise().then((objectData) => {
				console.log('fileInfo\n\n', objectData);
				if (objectData) {
					resolve({
						name: dataObj.fileName,
						type: objectData.ContentType,
						size: objectData.ContentLength,
						lastModified: objectData.LastModified
					});
				} else {
					resolve(dataObj.fileName);
				}
			});
		} else if (dataObj.action === 'downloadFile') {
			var params = {
				Bucket: dataObj.bucket,
				Key: prefix + dataObj.fileName
			};
			s3.getObject(params, function(err, data) {
				if (err) console.log(err, err.stack);
				else {
					// an error occurred
					const blob = new Blob([ data.Body ]);
					if (navigator.msSaveBlob) {
						// IE 10+
						navigator.msSaveBlob(blob, dataObj.fileName);
					} else {
						const link = document.createElement('a');
						// Browsers that support HTML5 download attribute
						if (link.download !== undefined) {
							const url = URL.createObjectURL(blob);
							link.setAttribute('href', url);
							link.setAttribute('download', dataObj.fileName);
							link.style.visibility = 'hidden';
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						}
					}
				} // successful response
				/*
				 data = {
					AcceptRanges: "bytes", 
					ContentLength: 3191, 
					ContentType: "image/jpeg", 
					ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"", 
					LastModified: <Date Representation>, 
					Metadata: {
					}, 
					TagCount: 2, 
					VersionId: "null"
				 }
				 */
			});
		} else if (dataObj.action === 'getAll') {
			params = {
				Bucket: 'mariatha',
				Prefix: prefix,
				Delimiter: '/'
			};

			s3.listObjectsV2(params, function(err, data) {
				console.log(data);
				if (!err) {
					var files = [];
					data.Contents.forEach(function(element) {
						files.push(element.Key.split('/').filter((name, index) => name.length > 0));
					});
					data.CommonPrefixes.forEach((ele) => {
						files.push(ele.Prefix.split('/').filter((name) => name.length > 0));
					});

					console.log(files);
					var parsedData = wasabiDataParser(files);
					console.log(parsedData);

					resolve(parsedData);
				} else {
					reject(err);
				}
			});
		} else if (dataObj.action === 'downloadFolder') {
			params = {
				Bucket: 'mariatha',
				Prefix: 'project/new_folder'
			};

			s3.listObjects(params, function(err, data) {
				if (err) return console.log(err);

				async.eachSeries(
					data.Contents,
					function(fileObj, callback) {
						var key = fileObj.Key;
						console.log('Downloading: ' + key);

						// var fileParams = {
						// 	Bucket: 'mariatha',
						// 	Key: key
						// };

						// s3.getObject(fileParams, function(err, fileContents) {
						// 	if (err) {
						// 		callback(err);
						// 	} else {
						// 		// Read the file
						// 		var contents = fileContents.Body.toString();

						// 		// Do something with file
						// 		console.log('FILE', contents);
						// 		callback();
						// 	}
						// });
					},
					function(err) {
						if (err) {
							console.log('Failed: ' + err);
						} else {
							console.log('Finished');
						}
					}
				);
			});
		} else if (dataObj.action === 'uploadFile') {
			var params = {
				Body: dataObj.fileData,
				Bucket: dataObj.bucket,
				Key: prefix + dataObj.fileName,
				ContentType: dataObj.type
				// Prefix: dataObj.prefix
			};
			var options = {
				partSize: dataObj.size, // 10 MB
				queueSize: 1
			};
			// console.log(prefix, 'fasdas', params, options);
			s3.upload(params, options, function(err, data) {
				if (err)
					console.log(err, err.stack); // an error occurred
				else console.log(data); // successful response
				/*
				 data = {
					ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"",
					VersionId: "Kirh.unyZwjQ69YxcQLA8z4F5j3kJJKr"
				 }
				 */
			});
		} else if (dataObj.action === 'delete') {
			var params = {
				Bucket: dataObj.bucket,
				Key: dataObj.name
			};

			s3.deleteObject(params, function(err, data) {
				if (!err) {
					console.log(data, 'delete'); // sucessfull response
					/*
						data = {}
						*/
				} else {
					console.log(err); // an error ocurred
				}
			});
		} else if (dataObj.action === 'deleteDirectory') {
			(async function emptyS3Directory(bucket, dir) {
				const listParams = {
					Bucket: bucket,
					Prefix: dir
				};

				console.log('listParams', listParams);

				const listedObjects = await s3.listObjectsV2(listParams).promise();

				if (listedObjects.Contents.length === 0) return;

				const deleteParams = {
					Bucket: bucket,
					Delete: { Objects: [] }
				};

				listedObjects.Contents.forEach(({ Key }) => {
					deleteParams.Delete.Objects.push({ Key });
				});

				await s3.deleteObjects(deleteParams).promise();

				console.log('DELETE DIR', listedObjects.IsTruncated);

				if (listedObjects.IsTruncated) await emptyS3Directory(bucket, dir);
			})(dataObj.bucket, dataObj.dir);
		}
	});
}
