import React, { Component } from 'react';

import Layout from '../components/layout';
import Helmet from 'react-helmet';
import SEO from '../components/seo';
import TextBox from '../components/FormElements/textBox';
import LightBox from '../components/lightBox';
import footerLogo from '../images/footer_logo.png';
import { isEmpty, map, filter } from 'lodash';
import '../utils/css/dashboard.css';
import downloadBtn from '../images/download-icon.svg';
import shareBtn from '../images/share-button.svg';

import wasabiActions from '../utils/wasabiActions';
import fileroomLogo from '../images/file_room.png';
// import { googleActions } from '../utils/googleActions';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchKey: '',
			rootFolder: [],
			isShowPopup: false,
			folderName: '',
			folderTrack: [],
			currentAddress: '',
			listWithInfo: [ 'Test' ],
			showBtnList: false,
			loading: false,
			checkedNames: [],
			selectedAll: false
		};
	}
	componentDidUpdate() {
		// console.log('hooho');
		// googleActions();
		// console.log('\n\n\nSTATE\n\n\n', this.state, '\n\n\nSTATE\n\n\n');
	}
	componentDidMount() {
		// console.log('UPDATE');
		// this.fetchAllFiles();
		// (() => setTimeout(googleActions, 3000))();
	}

	fetchAllFiles = () => {
		wasabiActions({
			action: 'getAll',
			bucket: 'anjaneya',
			fileName: 'Math_exponents',
			prefix: this.state.folderTrack.join('/')
		}).then(async (data) => {
			// console.log(data, 'OHHOHOOO');

			var dataList = Object.keys(this.parseData(data));

			const infoListWithPromises = await this.getInfoOfFiles(dataList);

			// console.log('infoList', infoListWithPromises);

			Promise.allSettled(infoListWithPromises).then((infoList) =>
				this.setState({ listWithInfo: infoList.map((info) => info.value), loading: false })
			);
		});
	};

	parseData = (data) => {
		// wasabi wraps the data inside an object which is named by the folder which contains the data, as we don't need the wrapper object, extract the data inside by checking whether we are looking data inside a folder.

		if (this.state.folderTrack.length > 0 && data) {
			// means we're inside a folder
			let resultObj = data;
			this.state.folderTrack.forEach((folderName) => {
				resultObj = resultObj[folderName];
			});
			// console.log('asdasdasd sdf', resultObj, this.state.folderTrack);
			return resultObj;
		}

		return data;
	};

	getInfoOfFiles = async (dataList) => {
		// console.log('getInfoForFiles', dataList);
		return dataList.map((data) => {
			if (this.isAfile(data)) {
				return this.getFileInfo(data);
			} else {
				return { name: data };
			}
		});
	};

	isAfile = (name) => {
		if (name.split('.').length > 1) {
			return true;
		}
		return false;
	};

	getFileInfo = async (fileName) => {
		// console.log('getFileInfo', `oho ${this.state.folderTrack.join('/')}`, fileName);
		var fileData = await wasabiActions({
			action: 'getFileInfo',
			bucket: 'mariatha',
			fileName: fileName,
			prefix: this.state.folderTrack.join('/')
		});
		// console.log('fileData', fileData);
		return fileData;
	};

	toggleBtnList = () => {
		this.setState({
			showBtnList: !this.state.showBtnList
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};
	// folderCreate = (e) => {
	// 	e.preventDefault();
	// 	const { rootFolder, folderName } = this.state;
	// 	const folderNameObj = [ { folderName: folderName, filesArray: [] } ];
	// 	this.setState((prevState, props) => {
	// 		return {
	// 			rootFolder: prevState.rootFolder.concat(folderNameObj),
	// 			currentFolderName: folderName,
	// 			folderName: ''
	// 		};
	// 	});
	// 	this.togglePopup(e);
	// };
	formatBytes(bytes, decimals) {
		if (bytes === 0) return '0 Bytes';

		const k = 1000;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
	getDate = () => {
		const today = new Date();
		const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
		const time = today.getHours() + ':' + today.getMinutes();
		return date + ' ' + time;
	};
	togglePopup = (e) => {
		e.preventDefault();
		this.setState({
			isShowPopup: !this.state.isShowPopup,
			folderName: ''
		});
	};
	// openFolder = (folderName) => {
	// 	const { rootFolder } = this.state;
	// 	const currentFolder = filter(rootFolder, (el) => el.folderName === folderName);
	// 	this.setState({
	// 		currentFolder,
	// 		currentFolderName: folderName
	// 	});
	// };
	myfiledownload(url) {
		window.location.href = '/url/to/downloadfile/';
	}
	// fileHandler = (e) => {
	// 	const { rootFolder, currentFolderName } = this.state;
	// 	const file = e.target.files[0];
	// 	// var name = file.name;
	// 	const self = this;
	// 	console.log(file);
	// 	var reader = new FileReader();
	// 	reader.onload = function() {
	// 		var dataURL = reader.result;
	// 		console.log(rootFolder, 'dataURL');
	// 		let fileObjArray = filter(rootFolder, (el) => el.folderName === currentFolderName)[0].filesArray;
	// 		// fileObjArray = fileObjArray ? fileObjArray.filesArray : fileObjArray;
	// 		// console.log(fileObjArray, 'fileObjArr1');
	// 		fileObjArray.push({
	// 			file: dataURL,
	// 			name: file.name,
	// 			size: self.formatBytes(file.size, 2),
	// 			date: self.getDate(),
	// 			type: file.type
	// 		});
	// 		// console.log(fileObjArray, 'fileObjArr2');
	// 		map(rootFolder, (el) => {
	// 			if (el.folderName === currentFolderName) {
	// 				el.filesArray = fileObjArray;
	// 			}
	// 		});
	// 		self.setState(
	// 			{
	// 				rootFolder
	// 			},
	// 			() => {
	// 				// console.log(rootFolder, 'TOTAL FILES 1');
	// 			}
	// 		);
	// 	};
	// 	reader.readAsDataURL(file);
	// };
	parseDateFormat = (dateData) => {
		if (dateData) {
			const dateObject = new Date(dateData);
			const month = dateObject.getMonth();
			const year = dateObject.getFullYear();
			const date = dateObject.getDate();

			return `${month}/${date}/${year}`;
		}
	};
	sizeConverter = (bytes) => {
		if (bytes < 1024) {
			// bytes
			return `${bytes} b`;
		} else if (bytes < 1048576) {
			// kilo bytes
			return `${(bytes / 1024).toFixed(2)} kb`;
		} else if (bytes < 1073741824) {
			// mega bytes
			return `${(bytes / 1048576).toFixed(2)} mb`;
		} else if (bytes) {
			// giga bytes
			return `${(bytes / 1073741824).toFixed(2)} gb`;
		}
	};

	goIntoFolder = (folderName) => {
		this.setState(
			(prevState) => ({
				folderTrack: [ ...prevState.folderTrack, folderName ],
				loading: true
			}),
			this.fetchAllFiles
		);
	};

	goBack = () => {
		if (this.state.folderTrack.length > 0) {
			this.setState(
				(prevState) => ({
					folderTrack: prevState.folderTrack.slice(0, -1),
					loading: true
				}),
				this.fetchAllFiles
			);
		}
	};
	downloadFile = async (fileName) => {
		var fileData = await wasabiActions({
			action: 'downloadFile',
			bucket: 'mariatha',
			fileName: fileName,
			prefix: this.state.folderTrack.join('/')
		});
		// console.log('fileData', fileData);
		return fileData;
	};
	onFileUpload = (element) => {
		// console.log(element.target.files);
		const files = element.target.files;
		for (let i = 0; i < files.length; i++) {
			this.uploadFile(files[i]);
		}
	};
	uploadFile = async (fileData) => {
		const readFile = (event) => {
			console.log('MHMM', event.target.result);

			var result = wasabiActions({
				action: 'uploadFile',
				bucket: 'mariatha',
				fileName: fileData.name,
				fileData: event.target.result,
				prefix: this.state.folderTrack ? this.state.folderTrack.join('/') : '',
				type: fileData.type,
				size: fileData.size * 1024 * 1024
			});
		};
		var reader = new FileReader();
		console.log('DATA', fileData);

		reader.readAsBinaryString(fileData);
		reader.addEventListener('load', readFile);

		// console.log(fileData);
	};

	delete = () => {
		this.state.checkedNames.forEach((name) => {
			if (this.isAfile(name)) {
				const fileName =
					this.state.folderTrack.length > 0 ? this.state.folderTrack.join('/') + '/' + name + '/' : name;
				var result = wasabiActions({
					action: 'delete',
					bucket: 'mariatha',
					name: fileName
				});
				console.log('wow', name + '/', result, this.state.folderTrack, fileName);
			} else {
				const dirName =
					this.state.folderTrack.length > 0 ? this.state.folderTrack.join('/') + '/' + name + '/' : name;
				var result = wasabiActions({
					action: 'deleteDirectory',
					bucket: 'mariatha',
					dir: dirName
				});
			}
		});
	};
	onSelect = (name, ele) => {
		const isChecked = ele.target.checked;
		if (isChecked) {
			this.setState((prevState) => {
				return { checkedNames: [ ...prevState.checkedNames, name ] };
			});
		} else {
			this.setState((prevState) => {
				const filteredList = prevState.checkedNames.filter((nameInList) => {
					return nameInList !== name;
				});
				return { checkedNames: filteredList };
			});
		}
		// console.log(name, isChecked);
	};
	onSelectAll = (e) => {
		if (e.target.checked) {
			const nameList = this.state.listWithInfo.map((ele) => {
				return ele.name;
			});
			this.setState({
				checkedNames: nameList,
				selectedAll: true
			});
			console.log(nameList);
		} else {
			this.setState({
				checkedNames: [],
				selectedAll: false
			});
		}
	};
	onGoogleAPILoad = (e) => {
		// alert('WOW');
		// googleActions();
	};

	handleScriptInject = ({ scriptTags }) => {
		// console.log('scripts', scriptTags);
		scriptTags.map((script) => {
			if (script.src === 'https://apis.google.com/js/api.js') {
				script.onload = this.onGoogleAPILoad;
			}
		});
	};
	render() {
		const { searchKey, folderName, isShowPopup, rootFolder, currentFolder, currentFolderName } = this.state;
		// console.log(rootFolder, 'TOTAL FILES');
		return (
			<Layout className="about-us-wrapper">
				<Helmet onChangeClientState={(newState, addedTags) => this.handleScriptInject(addedTags)}>
					<link rel="canonical" href="/dashboard" />
					<link
						rel="stylesheet"
						href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
						integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
						crossorigin="anonymous"
					/>
					<script async defer src="https://apis.google.com/js/api.js" onload={this.onGoogleAPILoad} />

					{/* <script src="https://apis.google.com/js/platform.js" async defer /> */}
				</Helmet>
				<SEO
					title={`Dashboard`}
					description={`This page describes about our cloud storage. ${Math.random()}`}
				/>
				<div className="nav-adjuster" />
				{!this.state.loading ? (
					<div className="dashboard">
						<div className="left-part">
							<img className="fileroom-pic" src={fileroomLogo} />

							<button
								className={`back-btn ${this.state.folderTrack.length === 0 ? 'hide' : ''}`}
								onClick={this.goBack}
							/>
							<button className="new-btn" onClick={this.toggleBtnList} />
							<div className={`btn-list ${this.state.showBtnList ? 'show-btn-list' : 'hide-btn-list'}`}>
								<button className="create-folder-btn dash-btn">Create folder</button>
								{/* <button className="create-file-btn dash-btn">Create file</button> */}
								{/* <button className="upload-folder dash-btn">Folder upload</button> */}
								<button
									className="upload-file dash-btn"
									onClick={() => document.getElementById('file-upload').click()}
								>
									<input
										type="file"
										name="fileUpload"
										id="file-upload"
										multiple
										onInput={this.onFileUpload}
										style={{ display: 'none' }}
									/>
									Upload files
								</button>
							</div>
						</div>
						<div className="right-part">
							<div className="address-bar">
								Fileroom
								{` ${this.state.folderTrack.length > 0
									? ' / ' + this.state.folderTrack.join(' / ')
									: ''}`}
								<div className="edit-btns-list">
									<button className="delete-btn" onClick={this.delete} />
									<button className="logout-btn" />
								</div>
							</div>
							<hr />

							{this.state.listWithInfo.length > 0 ? (
								<table className="file-table">
									<thead className="table-head">
										<tr>
											<th scope="col">
												<input type="checkbox" name="" id="" />
											</th>
											<th scope="col">File Name</th>
											<th scope="col">File Type</th>
											<th scope="col">Size</th>
											<th scope="col">Last Modified</th>
											<th scope="col">Download</th>
											<th scope="col">Share</th>
										</tr>
									</thead>

									<tbody className="table-body">
										{[
											{
												name: 'Sample.pdf',
												type: 'pdf',
												size: '500000',
												lastModified: '08/02/2019'
											},
											{
												name: 'Awesome.png',
												type: 'png',
												size: '10000',
												lastModified: '04/05/2019'
											},
											{
												name: 'Yellow.mpg',
												type: 'mpg',
												size: '300000',
												lastModified: '02/07/2019'
											}
										].map((ele) => {
											// console.log('ELE', ele);
											return (
												<tr className="table-body-row" key={Math.random()}>
													<td>
														<input type="checkbox" />
													</td>
													<td
														key={Math.random()}
														className={!this.isAfile(ele.name) ? 'folder-name' : ''}
													>
														{ele.name}
													</td>
													<td key={Math.random()}>{ele.type ? ele.type : '-'}</td>
													<td key={Math.random()}>
														{ele.size ? this.sizeConverter(ele.size) : '-'}
													</td>
													<td key={Math.random()}>
														{ele.lastModified ? (
															this.parseDateFormat(ele.lastModified)
														) : (
															'-'
														)}
													</td>
													<td key={Math.random()}>
														{!this.isAfile(ele.name) ? (
															'-'
														) : (
															<img
																className="download-btn"
																src={downloadBtn}
																height="25px"
																width="25px"
															/>
														)}
													</td>
													<td key={Math.random()}>
														{!this.isAfile(ele.name) ? (
															'-'
														) : (
															<img
																className="share-btn"
																src={shareBtn}
																height="25px"
																width="25px"
																// onClick={() => this.downloadFile(ele.name)}
															/>
														)}
													</td>
												</tr>
											);
										})}
									</tbody>

									{/* <tbody className="table-body">
										{this.state.listWithInfo.map((ele) => {
											// console.log('ELE', ele);
											return (
												<tr className="table-body-row" key={Math.random()}>
													<td>
														<input
															type="checkbox"
															onChange={(e) => this.onSelect(ele.name, e)}
															checked={this.state.checkedNames.includes(ele.name)}
														/>
													</td>
													<td
														onClick={() =>
															!this.isAfile(ele.name)
																? this.goIntoFolder(ele.name)
																: null}
														key={Math.random()}
														className={!this.isAfile(ele.name) ? 'folder-name' : ''}
													>
														{ele.name}
													</td>
													<td key={Math.random()}>{ele.type ? ele.type : '-'}</td>
													<td key={Math.random()}>
														{ele.size ? this.sizeConverter(ele.size) : '-'}
													</td>
													<td key={Math.random()}>
														{ele.lastModified ? (
															this.parseDateFormat(ele.lastModified)
														) : (
															'-'
														)}
													</td>
													<td key={Math.random()}>
														{!this.isAfile(ele.name) ? (
															'-'
														) : (
															<img
																className="download-btn"
																src={downloadBtn}
																height="25px"
																width="25px"
																onClick={() => this.downloadFile(ele.name)}
															/>
														)}
													</td>
													<td key={Math.random()}>
														{!this.isAfile(ele.name) ? (
															'-'
														) : (
															<img
																className="share-btn"
																src={shareBtn}
																height="25px"
																width="25px"
																// onClick={() => this.downloadFile(ele.name)}
															/>
														)}
													</td>
												</tr>
											);
										})}
									</tbody> */}
								</table>
							) : (
								<h3 className="empty-folder-title">Folder is empty.</h3>
							)}
						</div>
					</div>
				) : (
					<div className="loading">
						<div className="loading-roller" />
						{/* <span className="loading-text"></span> */}
					</div>
				)}
				{/* <LightBox isShowPopup={isShowPopup} togglePopup={this.togglePopup} title="Create Folder">
					<form onSubmit={this.folderCreate}>
						<input
							name="folderName"
							value={folderName}
							placeholder="Enter your folder name"
							onChange={this.handleChange}
						/>
						<div className="button-row">
							<button onClick={this.togglePopup}>Cancel</button>
							<button onClick={this.folderCreate}>Create</button>
						</div>
					</form>
				</LightBox> */}
			</Layout>
		);
	}
}

export default Dashboard;
